.main {
  display: grid;
  grid-template-columns: .6fr .4fr;
  grid-template-areas: 'a b';
  column-gap: 20px;
  margin-left: 220px;
  padding: 20px;
  width: calc(100% - 220px);
  background-color: var(--bg-grey0);
}

.sub_app {
  grid-area: a;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e9ebf2;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 95vh;
}

.noData {
  color: var(--soft-grey4);
  font-size: 12px;
  line-height: 16px;
}

.wheels {
  grid-area: b;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e9ebf2;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.text {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
}
.text_wheel {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
}

.catalogButton {
  max-height: 36px;
  min-height: 36px;
}

h1 {
  padding: 30px 0;
}

@media screen and (max-width: 1193px) {
  .main {
    grid-template-columns: 1fr 366px;
  }
}

@media screen and (max-width: 1038px) {
  .main {
    grid-template-columns: 1fr;
    grid-template-areas:
      'a'
      'b';
    row-gap: 12px;
  }
}

@media screen and (max-width: 912px) {
  .main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'a'
      'b';
    margin-left: 0;
    margin-top: 56px;
    width: 100%;
    padding: 12px;
  }
  .sub_app {
    padding: 16px;
  }
  .wheels {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    //padding: 7px;
  }
  .text {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  .text_wheel {
    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 520px) {
  .main {
    padding: 0;
    margin-top: 64px;
  }
  .wheels {
    padding: 16px;
    margin-bottom: 12px;
  }
}
@media (min-width: 1039px) and (max-width: 1100px) {
  .main {
    grid-template-columns: 1fr 300px;
  }
}
