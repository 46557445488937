.container {
  border: 1px solid var(--lines-grey2);
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  padding: 16px 12px;
  align-items: center;
  margin-bottom: 16px;
}

.container .checkboxElement:nth-child(2) {
  margin-left: auto;
  margin-right: 12px;
}

.checkbox {
  display: flex;
  align-items: center;
  height: 100%;
}

.checkbox::before {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 4px;
  background-color: var(--input-grey1);
  border: 1px solid var(--soft-grey4);
  margin-right: 8px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
}

.checkbox input {
  position: absolute;
  z-index: -9999;
  opacity: 0;
}

.checked {
  border-color: var(--cta-red-default);
  background-color: #fff8f8;
}
.checked::before {
  background-color: var(--cta-red-default);
  background-image: url(../../assets/icons/check.svg);
  border-color: var(--cta-red-default);
}

.checkboxElement {
  display: flex;
  align-items: center;
}

.boxTitle {
  color: var(--icons-grey5);
  font-size: 14px;
  line-height: 20px;
  margin-right: 12px;
}

.checkboxContent {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-datk);
}

.checkboxSubcontent {
  font-size: 12px;
  margin-left: 4px;
  color: var(--icons-grey5);
}

@media screen and (max-width: 912px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    .checkboxElement {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 20px 0;
    }

    .checkboxElement:nth-child(1) {
      width: 100%;
      padding: 0 0 18px 0;
      border-bottom: #e9ebf2 solid 1px;
    }
    .checkboxElement:nth-child(2) {
      margin: 0;
      margin-bottom: 20px;
    }
    .checkboxElement:last-child {
      margin-bottom: 0;
    }
    .boxTitle {
      margin-bottom: 14px;
    }
  }
}
