.button {
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  padding: 6px 8px;
  border-radius: 4px;
  transition: all 0.1s ease-in-out;
  border: none;
  font-family: 'Roboto';
  font-weight: 400;
  min-height: 40px;
  cursor: pointer;
}

.primary {
  composes: button;
  background-color: var(--cta-red-default);
  color: #fff;
}

.primary:hover {
  background-color: var(--cta-red-hover);
}

.primary:active {
  background-color: var(--cta-red-pressed);
}

.secondary {
  composes: button;
  background-color: #fff;
  color: var(--text-datk);
  border: 1px solid var(--soft-grey4);
}

.secondary:hover {
  color: var(--cta-red-hover);
  border-color: var(--cta-red-hover);
}

.secondary:active {
  color: var(--cta-red-pressed);
  border-color: var(--cta-red-pressed);
}

.secondary:disabled,
.secondary[disabled] {
  border-color: var(--soft-grey4);
  color: var(--disablet-text-grey);
}

.primary:disabled,
.primary[disabled] {
  background-color: var(--cta-red-disabled);
}

// Success Button
.success {
  composes: button;
  background-color: var(--success);
  color: #fff;
}

.success:hover {
  background-color: var(--success);
}

.success:active {
  color: #fff;
}

.success:disabled,
.success[disabled] {
  border-color: var(--soft-grey4);
  color: var(--disablet-text-grey);
}
