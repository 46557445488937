.textContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 34px;
  align-items: center;
  justify-content: center;
}

.footer {
  padding: 20px;
  border-top: 1px solid var(--lines-grey2);
}

.logo {
  margin-bottom: 20px;
  height: 100px;
  width: 100px;
}

.description {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-datk);
  text-align: center;
}

@media screen and (max-width: 912px) {
  .footer {
    padding: 20px;
  }

  .textContainer {
    padding: 32px 30px;
  }
}

@media screen and (max-width: 520px) {
  .logo {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }
}
