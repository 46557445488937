.modalBackground {
  position: fixed;
  z-index: 9998;
  background-color: rgba(51, 68, 85, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  max-width: 680px;
  min-width: 680px;
  z-index: 9999;
}

.modalTitle {
  margin: 20px 20px 24px 20px
}

.subtitle {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Roboto';
  color: var(--icons-grey5);
  margin-bottom: 5px;
}

.dialogContainer {
  padding: 8px 12px;
  background-color: var(--bg-grey0);
  border-radius: 4px;
  margin: 20px;
  margin-top: 0;
  margin-bottom: 24px;
  min-height: 160px;
  display: flex;
  flex-direction: column;
}

.closeButton {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 12px;
  outline: none;
  background: none;
  border:none;
  cursor: pointer;
}

.closeButton:hover .closeImg path {
  fill: var(--cta-red-hover);
}

.buttonsGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 150px;
  border-top: 1px solid var(--lines-grey2);
}
.buttonsGroup button:last-child {
  margin-left: 20px;
}

.textInput {
  width: 100%;
  min-height: 140px;
  max-height: 300px;
  outline: none;
  background: none;
  border:none;
  resize: vertical;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

@media screen and (max-width: 720px) {
  .modalContainer {
    max-width: 95%;
    min-width: 95%;
  }
  .buttonsGroup {
    padding-left: 20px;
    padding-right: 20px;
  }
}