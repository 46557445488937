.container {
  background-color: #fff;
  max-width: 100%;
  min-height: 328px;
  display: grid;
  border-radius: 4px;
  grid-template-columns: 220px 1fr;
  border: 1px solid var(--lines-grey2);
}

.avatarBlock {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--lines-grey2);
  max-width: 220px;
  padding: 20px;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
}

.avatarBlock a {
  transition: color 0.2s ease-in-out;
  color: var(--blue-links);
}

.avatarBlock a:hover {
  color: var(--cta-red-hover);
}

.infoBlock {
  display: grid;
  grid-template-columns: 1fr 1fr 191px;
  row-gap: 16px;
  grid-template-areas:
    'title title button'
    'info info info'
    'bio bio bio'
    'bio2 bio2 bio2';
  padding: 20px;
}

.title {
  grid-area: title;
  display: flex;
  flex-direction: column;
}

.title h4 {
  margin: 0;
  margin-bottom: 12px;
}
.button {
  grid-area: button;
  min-height: 24px;
  max-height: 24px;
  font-family: 'Roboto';
  font-size: 12px;
  padding: 4px 16px;
  line-height: 16px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-self: flex-start;
  padding-left: 30px;
  position: relative;
}
.button:hover path {
  fill: var(--cta-red-hover);
}
.button:active path {
  fill: var(--cta-red-pressed);
}

.button:disabled path {
  fill: var(--soft-grey4);
}

.buttonIcon {
  width: 12px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 44px;
  grid-template-areas: '. . .';
  grid-area: info;
}
.bio {
  grid-area: bio;
}
.bio2 {
  display: grid;
  grid-template-columns: 47.5% 50%;
  grid-template-rows: 68px;
  grid-gap: 0px 20px;
  grid-template-areas: '. .';
  grid-area: bio2;
}

.userAvatar {
  width: 100% !important;
  height: 100% !important;
}

.avatarWrapper {
  width: 180px;
  height: 180px;
  padding: 6px;
  border: 1px solid var(--lines-grey2);
  border-radius: 50%;
}

.titleBlock {
  display: flex;
  grid-area: 1 / 1 / 2 / 6;
}

.motoNumber {
  display: inline-block;
  background-color: var(--input-grey1);
  padding: 4px;
  border-radius: 4px;
  margin-left: 10px;
}
.birthday {
  color: var(--icons-grey5);
  font-size: 12px;
  line-height: 24px;
}
.notFilled {
  color: var(--soft-grey4) !important;
  font-family: 'Roboto';
}

.infoContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.infoTitle {
  font-size: 12px;
  color: var(--icons-grey5);
  margin-bottom: 8px;
}

.infoValue {
  font-size: 14px;
}

.bioBlock {
  padding: 12px;
  width: 100%;
  background-color: var(--bg-grey0);
  display: flex;
  border-radius: 4px;
}

.locationImg {
  display: flex;
  align-items: flex-end;
  svg {
    margin-right: 6px;
    border-radius: 2px;
    box-shadow: 0px 0px 4px #00000026;
  }
}

.bioMain {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 130px);
}

.bioBlock span {
  color: var(--icons-grey5);
  font-size: 12px;
  border-radius: 4px;
}

.bioBlock p {
  color: var(--text-datk);
  font-size: 14px;
  margin: 0;
  margin-top: 8px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  white-space: nowrap;
}

.bioBlock .notFilled {
  font-size: 14px;
}

.avatarMobile {
  display: none !important;
  border: 1px solid var(--lines-grey2);
}

.showMore {
  background-color: var(--bg-grey0);
  width: 120px;
  margin-left: auto;
  vertical-align: bottom;
  align-self: flex-end;
}

@media screen and (max-width: 1278px) {
  .infoBlock {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 16px;
    grid-template-areas:
      'title title button'
      'info info info'
      'bio bio bio'
      'bio2 bio2 bio2'
      'bio2 bio2 bio2';
    padding: 20px;
    grid-template-rows: 1fr 68px 68px 68px;
  }

  .bio2 {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 16px 20px;
    grid-template-areas:
      '.'
      '.';
  }
}

@media screen and (max-width: 1083px) {
  .infoBlock {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 16px;
    grid-template-areas:
      'title title button'
      'info info info'
      'bio bio bio'
      'bio2 bio2 bio2'
      'bio2 bio2 bio2';
    padding: 20px;
    grid-template-rows: 1fr 68px 68px 68px;
  }
  .button {
    max-height: fit-content;
  }
}

@media screen and (max-width: 768px) {
  .infoBlock {
    grid-template-areas:
      'title button'
      'info info info'
      'bio bio bio'
      'bio2 bio2 bio2'
      'bio2 bio2 bio2';
    padding: 12px;
  }
}

@media screen and (max-width: 676px) {
  .infoBlock {
    display: grid;
    width: 100%;
    grid-template-columns: 170px 1fr;
    grid-template-areas:
      'am title'
      'am button'
      'info info'
      'bio bio'
      'bio2 bio2'
      'bio2 bio2';
  }

  .avatarBlock {
    display: none;
  }
  .avatarMobile {
    display: flex !important;
    grid-area: am;
    width: 140px !important;
    height: 140px !important;
  }
  .container {
    display: flex;
  }
}

@media screen and (max-width: 574px) {
  .container h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 490px) {
  .infoBlock {
    grid-template-areas:
      'am title'
      'button button'
      'bio bio'
      'bio2 bio2';
  }
  .avatarMobile {
    width: 56px !important;
    height: 56px !important;
  }
  .infoBlock {
    grid-template-columns: 70px 1fr;
    grid-template-rows: 1fr;
  }
  .title {
    justify-content: center;
    position: relative;
    padding-right: 50px;
  }
  .title h4 span {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }

  .infoBlock h4 {
    margin-bottom: 0;
  }
  .birthday {
    display: none;
  }

  .info {
    display: none;
  }
  .bioBlock {
    flex-direction: column;
  }
  .notFilled {
    display: none;
  }
  .bio {
    max-height: max-content;
  }
  .bio2 {
    grid-template-rows: max-content;
  }
}
