.auth {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
}

.logo {
  margin-top: 40px;
}

.textButton {
  max-width: 55px;
  margin-bottom: 24px;
}

.icon {
  margin-right: 5px;
}

.phoneBlock {
  margin-top: 32px;
  margin-bottom: 20px;
  height: 20px;
  display: flex;
  align-items: center;
}

.phone {
  font-family: 'Roboto';
  color: var(--text-datk);
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
}

.text2 {
  margin-top: 0;
}

.errorIcon {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.error {
  border: 1px solid var(--cta-red-default);
  border-radius: 4px;
}

.form {
  max-width: 440px;
  min-width: 440px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--lines-grey2);
}

.title {
  margin: 0;
}

.inputBlok {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 32px 0;
}

.input {
  padding: 8px 12px;
  background-color: var(--input-grey1);
  outline: none;
  border: none;
  padding-top: 28px;
  color: var(--text-datk);
  font-size: 14px;
  line-height: 16px;
  transition: all 0.1s ease-in-out;
  border: 1px solid var(--input-grey1);
  border-radius: 4px;
}

.label {
  position: absolute;
  transition: all 0.2s ease-in-out;
  top: 10px;
  left: 12px;
}

.text {
  font-size: 12px;
  color: var(--icons-grey5);
  line-height: 16px;
  margin: 0;
  margin-top: 40px;
}

@media screen and (max-width: 520px) {
  .form {
    max-width: 100%;
    min-width: 100%;
  }
}

@media  screen and (max-width: 820px) {
  .form {
    position: initial;
    transform: translate(0, 0);
    margin-top: 10%;
  }
}