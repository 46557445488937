.timerBlock {
  display: flex;
  margin-top: 40px;
}

.timer {
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
}