.container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  row-gap: 24px;
  grid-template-areas:
    'half'
    'half'
    '.';
}

.half {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px 24px;
  grid-template-areas:
    '. .'
    '. .';
  grid-area: half;
}

@media screen and (max-width: 550px) {
  .half {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      '.'
      '.';
  }
}
