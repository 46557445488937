.cartContainer {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid #e9ebf2;
  border-radius: 4px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.blockBox {
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #e9ebf2;
}

.leftContent {
  display: flex;
  align-items: center;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
}

.dateTitle {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 18px;
  color: #7d8598;
}

.dateContent {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #334455;
  line-height: 22px;
}

.priceBlock {
  font-family: Jost;
  font-weight: 500;
  font-size: 16px;
  color: #334455;
}

.titleContainer {
  border-bottom: 1px solid #e9ebf2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}

.indexBox {
  border: 1px solid #e9ebf2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  color: #d20000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Jost;
  font-weight: 500;
  font-size: 14px;
  background: #f6f7f9;
}

.totalPrice {
  padding: 12px;
  margin: 12px 0 0;
  border-radius: 4px;
  background-color: #f6f7f9;
  display: flex;
  justify-content: space-between;
}

.titlePrice {
  font-family: Jost;
  font-weight: 500;
  font-size: 14px;
  color: #334455;
}

.totalTitlePrice {
  font-family: Jost;
  font-weight: 500;
  font-size: 16px;
  color: #334455;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.titleInfo {
  display: flex;
  align-items: center;
}

.imgDescr {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
}

.logo {
  width: 38.57px;
  height: 40px;
}

.eventLocation {
  font-family: Jost;
  line-height: 22px;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #334455;
}

.eventName {
  font-family: Roboto;
  font-size: 12px;
  color: #334455;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
}

.pricePhotoBlock {
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  color: #7d8598;
  text-align: end;
}

.payButton {
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  color: #7d8598;

  display: flex;
  align-items: center;

  text-align: end;

  span {
    position: relative;
    font-weight: 400;
    &:after {
      content: "";
      width: 1px;
      height: 100%;
      position: absolute;
      right: -8px;
      top: 0;

      background-color: #E9EBF2;
    }
  }

  .pay {
    font-family: Roboto;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: white;

    display: flex;
    align-items: center;

    margin-left: 16px;
    padding: 4px 16px;
    border-radius: 4px;

    background-color: #D20000;
    border: none;
    cursor: pointer;

    img {
      margin-right: 8px;
    }

    &:hover {
      background-color: #c70000;
      color: #e7e7e7;
      img {
        opacity: .9;
      }
    }

  }
}

.paidButton {

  .completely {
    font-family: Roboto;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #27AE60;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4px 16px;
    text-align: end;

    background-color: #edf7f1;
    border-radius: 4px;
  }

  .partially {
    font-family: Roboto;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #856404;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4px 16px;
    text-align: end;

    background-color: #fff3cd;
    border-radius: 4px;
  }

  img {
    margin-right: 8px;
  }
}

@media screen and (max-width: 912px) {
  .cartContainer {
    width: 100%;
  }
}

@media screen and (max-width: 460px) {
  .cartContainer {
    width: 100%;
  }

  .titleContainer {
    align-items: flex-start;
    flex-direction: column;
  }

  .buttons {
    width: 100%;
    flex-direction: column;
    margin: 8px 0 0;
  }

  .payButton {
    align-items: flex-start;
    flex-direction: column;

    span {
      margin-bottom: 8px;
      &:after {
        display: none;
      }
    }

    .pay {
      margin-left: 0;
      width: 100%;
      justify-content: center;
    }
  }

  .paidButton {
    justify-content: center;
  }
}
