/* Colors */
:root {
  --cta-red-default: rgba(215, 2, 2, 0.99);
  --cta-red-hover: #ED1515;
  --cta-red-pressed: #B81A1A;
  --cta-red-disabled: #FFDDDD;
  --text-datk: #334455;
  --icons-grey5: #7D8598;
  --soft-grey4: #BBBFC9;
  --lines-grey2: #E9EBF2;
  --input-grey1: #F6F7F9;
  --bg-grey0: #FAFAFB;
  --warning: #FFA801;
  --links: #2F80ED;
  --success: #27AE60;
  --disablet-text-grey: #B8BEC4;
  --blue-links: #2F80ED;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
  font-display: auto;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: 700;
  font-display: auto;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-display: auto;
}

@font-face {
  font-family: 'Jost';
  font-weight: 400;
  font-display: auto;
  src: url(./assets/fonts/Jost-Regular.ttf) format('truetype'),
    url(./assets/fonts/Jost-Regular.woff2) format('woff2'),
    url(./assets/fonts/Jost-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Jost';
  font-weight: 500;
  font-display: auto;
  src: url(./assets/fonts/Jost-Medium.ttf) format('truetype'),
    url(./assets/fonts/Jost-Medium.woff2) format('woff2'),
    url(./assets/fonts/Jost-Medium.woff) format('woff');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-grey0);
  color: var(--text-datk);
}

h2 {
  font-family: 'Jost';
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

h3 {
  font-family: 'Jost';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

h4 {
  font-family: 'Jost';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

label {
  font-size: 14px;
  font-weight: 400;
  color: var(--icons-grey5);
}

input {
  font-family: 'Roboto';
}

a {
  color: var(--cta-red-default);
  text-decoration: none;
  transition: color .2s ease-in-out;
}

a:hover {
  color: var(--cta-red-hover);
}

.MuiFilledInput-root {
  border: 1px solid var(--input-grey1)!important;
  border-radius: 4px!important;
  background-color: var(--input-grey1)!important;
}

label.Mui-focused {
  color: #7D8598!important;
}

.MuiFilledInput-root.Mui-focused {
  border: 1px solid #7D8598!important;
  border-radius: 4px!important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px var(--bg-grey0) inset !important;
}