.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin-top: 40px;
  margin-bottom: 40px;
}

.form {
  padding: 40px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid var(--lines-grey2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 680px;
  margin-bottom: 80px;
}

.title {
  margin-top: 24px;
  margin-bottom: 32px;
}

.button {
  margin-top: 32px;
}

@media screen and (max-width: 680px) {
  .form {
    min-width: 100%;
    padding: 40px 16px;
  }
}
