.main {
  margin-left: 220px;
  min-height: 100vh;
  padding: 20px;
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  grid-template-rows: max-content 1fr;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-areas:
    'h h'
    'e r';
}

.header {
  grid-area: h;
}
.events {
  grid-area: e;
}
.results {
  grid-area: r;
}

.navBackground {
  opacity: 0;
  transition: opacity .2s ease-in-out;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 68, 85, 0.5);
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
}

.expanded {
  opacity: 1;
  z-index: 99;
}

@media screen and (max-width: 912px) {
  .main {
    margin-left: 0;
    margin-top: 44px;
    padding: 12px;
    padding-top: 20px
  }
}

@media screen and (max-width: 812px) {
  .main {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 520px) {
  .main {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 676px) {
  .main {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr 1fr;
    grid-template-areas:
      'h'
      'e'
      'r';
  }
}

