.participation {
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: flex-start;
    border: 1px solid var(--lines-grey2);
}

.participationContainer {
    display: grid;
    margin-left: 220px;
    min-height: 100vh;
    padding: 20px;
    grid-template-columns: 1.2fr 0.8fr;
    column-gap: 20px;
    row-gap: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.block {
    padding-top: 24px;
    border-top: 1px solid var(--lines-grey2);
    display: flex;
    flex-direction: column;
}

.buttonsBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 16px;
    row-gap: 8px;
    margin-bottom: 21px;
}

.subtitle {
    color: var(--icons-grey5);
    font-size: 14px;
    margin-bottom: 12px;
}

.selectInner {
    display: flex;
    align-items: center;
    font-size: 12px;
    border: 1px solid var(--lines-grey2);
    border-radius: 4px;
    padding: 12px 10px;
    padding-right: 72px;
    cursor: pointer;
    background-color: #fff;
    width: 100%;
    max-height: 56px;
    position: relative;
    &:disabled {
        color: #b8bec4;
    }
}

.imageContainer {
    height: 40px;
    max-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right: 12px;
}

.date {
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.image {
    width: auto;
    height: 100%;
}

.expand {
    position: relative;
    cursor: pointer;
    text-align: left;
    align-items: center;
    outline: none;
    border: none;
    background-color: #fff;
    margin-bottom: 24px;
}

.expandArrow {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    transition: all 0.2s ease-in-out;
    transform: scale(-1) translateY(50%);
    right: 20px;
}

.expandArrow path {
    fill: var(--text-datk);
}
.arrowExpanded {
    transform: scale(1) translateY(-50%);
}

.expandMenu {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    top: 75px;
    left: 0;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.isExpanded {
    border-color: var(--text-datk);
}

.formTitle {
    margin-top: 16px;
    margin-bottom: 32px;
}

.optionTitle {
    margin-bottom: 12px;
}

.withBorderTop {
    padding-top: 24px;
    border-top: 1px solid var(--lines-grey2);
}

.withBorderBottom {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--lines-grey2);
}

.footerForward {
    font-size: 14px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    color: var(--icons-grey5);
}

.doubleArrow {
    margin-left: 12px;
}
.descriptionContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.titleContainer {
    text-align: left;
}

.serviceCheckbox {
    margin-bottom: 24px;
}

.serviceCheckbox:last-child {
    margin-bottom: 24px;
}

.raceClassesBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--lines-grey2);
}

.hasOrder {
    color: #27ae60;
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;
    text-align: right;
}

.checkIcon {
    margin-right: 8px;
}

@media screen and (max-width: 912px) {
    .participationContainer {
        margin: 0 12px;
        grid-template-columns: 1fr;
        padding: 20px 0;
        margin-top: 45px;
    }
    .buttonsBlock {
        grid-template-columns: 1fr;
        margin-bottom: 24px;
    }
    .descriptionContainer {
        flex-direction: column;
        align-items: flex-start;
    }
    .titleContainer {
        p {
            display: none;
        }
    }
    .date {
        margin: 0;
    }
    .formTitle {
        font-size: 16px;
        margin-bottom: 24px;
    }
    .optionTitle {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .date {
        flex-direction: unset;
        width: 100%;
    }
    .hasOrder {
        margin-top: 0;
        margin-left: auto;
        position: absolute;
        top: 13px;
        right: 10px;
    }
    .selectInner {
        padding-right: 10px;
    }
}

@media screen and (max-width: 520px) {
    .participationContainer {
        margin-left: 0;
        margin-right: 0;
    }
}
