.container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--lines-grey2);
  border-radius: 4px;
}

.container h2 {
  margin-bottom: 20px;
}

.tabsHeader {
  display: flex;
  padding: 20px;
  padding-bottom: 0;
  flex-direction: column;
}
.tabContainer {
  display: flex;
  background: none;
  flex-wrap: wrap;
}

.tabLinks {
  position: relative;
  outline: none;
  border: none;
  background: none;
  color: var(--icons-grey5);
  padding-bottom: 2px;
  font-size: 12px;
  line-height: 20px;
  margin-right: 28px;
  margin-bottom: 20px;
  transition: color .2s ease-in-out;
  cursor:pointer;
}

.tabLinks:hover {
  color: var(--cta-red-hover);
}

.tabLinks:after {
  content: '';
  transition: all 0.2s ease-in-out;
  transform: scaleX(0);
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  background-color: var(--cta-red-default);
}

.active {
  color: var(--text-datk);
}

.active::after {
  content: '';
  transform: scaleX(1);
}

.tabContent {
  display: flex;
  flex-direction: column;
}

.tabItem {
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 1px solid var(--lines-grey2);
  border-bottom: 1px solid var(--lines-grey2);
  svg {
    margin-left: 10px;
  }
}

.tabItem span {
  font-family: 'Jost';
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.number {
  margin-right: 12px;  
}

.score {
  margin-left: auto;
}

.tabItem:nth-child(2n + 1) {
  background-color: var(--bg-grey0);
}

.noData {
  margin-top: 20px;
  color: var(--soft-grey4);
  font-size: 12px;
}