.container {
  display: flex;
  flex-direction: row;
  padding: 12px;
  border: 1px solid var(--lines-grey2);
  border-radius: 4px;
  margin-bottom: 12px;
  justify-content: space-between;
}

.mediaBlock {
  display: flex;
}

.imgBlock {
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 12px auto 0;

  > img {
    max-width: 48px;
    max-height: 48px;
  }
}

.main {
}

.priceBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.name {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-datk);
  margin-bottom: 8px;
}

.counterBlock {
  margin-right: 12px;
}

.counter {
  display: flex;
  align-items: center;
}

.minus {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--lines-grey2);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
  padding: 3px;

  &:hover {
    cursor: pointer;
  }
  background-color: #fff;
}

.disabled {
  background-color: #f6f7f9 !important;
}

.input {
  > div {
    width: 42px;
    height: 24px;
    border: 1px solid var(--lines-grey2);
    outline: none;
    box-shadow: none;
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--text-datk);
    border-radius: 0;
    background-color: var(--input-grey1);
    justify-content: center;
    pointer-events: none;
  }
}

.plus {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--lines-grey2);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
  padding: 3px;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
}

.price {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-datk);
  pointer-events: none;
}

.actionBlock {
  display: flex;
  align-items: flex-end;
  text-align: right;
}

.info {
  height: 24px;
  width: 24px;
  border: 1px solid var(--lines-grey2);
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    cursor: pointer;
  }
}

.delete {
  height: 24px;
  width: 24px;
  border: 1px solid var(--lines-grey2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    height: 16px;
    width: 16px;
    > path {
      fill: var(--cta-red-default);
    }
  }

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 520px) {
  .price {
    font-size: 12px;
    line-height: 16px;
  }
}