.commentButton {
  display: flex;
  background-color: #fff;
  height: 100%;
  max-width: 240px;
  margin-left: auto;
  padding: 8px;
  border: 1px solid #E9EBF2;
  border-radius: 4px;
}

.commentTextBlock {
  display: flex;
  justify-content: center;
  align-content: center;
}
.commentTextBlock span {
  color: var(--icons-grey5);
  font-size: 11px;
  line-height: 14px;
  width: calc(100% - 24px);
  padding-right: 12px;
}
.commentIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.button {
  padding-left: 12px;
  border-left: 1px solid var(--lines-grey2);
}

@media screen and (max-width: 490px) {
  .commentButton {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }
  .button {
    margin-left: auto;
  }
}
