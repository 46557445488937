.avatarWrapper {
  width: 180px;
  height: 180px;
  padding: 6px;
  border: 1px solid var(--lines-grey2);
  border-radius: 50%;
}

.inputWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lines-grey2);
  border-radius: 50%;
  position: relative;
}

.inputWrapper input {
  display: none;
}

.inputLabel {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.cameraIcon {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.userImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.buttonsHover {
  position: absolute;
  transition: all .2s ease-in-out;
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  bottom: 0;
  background: linear-gradient(180deg, rgba(51,68,85,0) 40%, rgba(51,68,85,0.8) 40%, rgba(51,68,85,0.8) 100%);
  border-radius: 50%;
  justify-content: flex-end;
  z-index: 999;
  padding-bottom: 10%;
  opacity: 0;
}

.buttonsHover label {
  transition: all .2s ease-in-out;
  outline: none;
  border: none;
  background: none;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  font-family: 'Roboto';
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  justify-content: center;
  cursor: pointer;
}

.buttonsHover path {
  transition: all .2s ease-in-out;
  fill: #fff;
}

.buttonIcon {
  margin-right: 5px;
  width: 16px;
}

.buttonsHover label:hover {
  color: var(--cta-red-hover)
}
.buttonsHover label:hover path {
  fill: var(--cta-red-hover)
}

.inputWrapper:hover .buttonsHover {
  opacity: 1;
}