.button {
  display: flex;
  align-items: center;
  color: var(--cta-red-default);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  transition: color .2s ease-in-out;
  background-color: #fff;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: 'Roboto';
}

.button:hover {
  color: var(--cta-red-hover);
}

.button:active {
  color: var(--cta-red-pressed);
}

.button:disabled {
  color: var(--soft-grey4);
}

.arrow {
  margin-right: 5px;
}

.button:hover .arrow path {
  fill: var(--cta-red-hover);
}

.button:active .arrow path {
  fill: var(--cta-red-pressed);
}

.button:disabled .arrow path {
  fill: var(--soft-grey4);
}