.radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 17px 12px;
  background-color: #fff;
  border: 1px solid var(--lines-grey2);
  border-radius: 4px;
  color: var(--text-datk);
  position: relative;
  padding-left: 40px;

  span {
    color: var(--text-datk);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}

.radio span:last-child {
  margin-left: auto;
}

.radio::before {
  content: '';
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  border-radius: 50%;
  background-color: var(--input-grey1);
  border: 1px solid var(--soft-grey4);
  background-position: center;
  background-repeat: no-repeat;
  transition: all .2s ease-in-out;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
}

.input {
  position: absolute;
  z-index: -9999;
  opacity: 0;
}

.checked {
  border-color: var(--cta-red-default);
  background-color: rgba(255, 221, 221, 0.2);
}
.checked::before {
  background-image: url(../../assets/icons/round.svg);
  border-color: var(--cta-red-default);
}
