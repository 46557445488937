.container {
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--lines-grey2);
}

.container h2 {
  margin-bottom: 20px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
}