.cashlessContainer {
  display: grid;
  margin-left: 220px;
  min-height: 100vh;
  padding: 20px;
  column-gap: 20px;
  row-gap: 20px;


  @media screen and (max-width: 912px) {
    margin-left: 0;
    margin-top: 45px;
    padding-left: 12px;
    padding-right: 12px;
    min-height: initial;
  }

  @media screen and (max-width: 522px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.cashless {
  background-color: #fff;
  border: 1px solid var(--lines-grey2);
}

.formCashless{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.formWrapper {
  display: grid;
  width: 50%;
  padding: 0 20px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 24px;
  row-gap: 24px;
  grid-template-areas:
        'half'
        'half'
        '.';

  @media screen and (max-width: 912px) {
    width: 100%;
  }
}

.formTitle {
  margin-top: 16px;
  margin-bottom: 8px;

  @media screen and (max-width: 912px) {
    margin-bottom: 24px;
    font-size: 16px;
  }
}

.formSubtitle {
  margin-bottom: 16px;

  @media screen and (max-width: 912px) {
    margin-top: 0;
    font-size: 14px;
  }
}

.formDetail {
  margin-top: 8px;

  span {
    font-size: 12px;
    color: var(--icons-grey5);
    margin-bottom: 20px;
    line-height: 16px;

    @media screen and (max-width: 912px) {
      margin-top: 0;
      font-size: 12px;
    }
  }
}

.MuiFormControl {
  width: 100%;
}

.buttons {
  padding: 19px 0;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--lines-grey2);

  @media screen and (max-width: 912px) {
    border-top: 0;
    position: initial;
    padding-top: 32px;
    padding-bottom: 40px;
  }

  .button {
    max-width: 180px;
    margin-right: 20px;

    @media screen and (max-width: 522px) {
      &:first-child {
        margin-bottom: 16px;
      }

      max-width: 100% !important;
      min-width: inherit;
    }
  }

  @media screen and (max-width: 522px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
  }
}
