.container {
  display: flex;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--lines-grey2);
  border-radius: 4px;
  max-height: 56px;
  padding: 10px 12px;
  align-items: center;
  color: var(--text-datk);
  font-size: 12px;
  margin-bottom: 12px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  &:disabled {
    color: #B8BEC4;
  }
  &:disabled:hover {
    &::after {
      content: 'Вы уже зарегистрированы на данное мероприятие';
      position: absolute;
      height: max-content;
      max-width: 50%;
      padding: 10px;
      bottom: 0;
      right: 0;
      background-color: var(--text-datk);
      color: #fff;
      border-radius: 4px;
      transform: translateY(50%);
      z-index: 900;
    }
  }
}
.container:hover {
  border-color: var(--cta-red-hover);
}

.container:hover path {
  fill: var(--cta-red-hover);
}
.imageContainer {
  height: 40px;
  max-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 12px;
}
.image {
  width: auto;
  height: 100%;
}

.date {
  margin-left: auto;
}

.arrow {
  margin-left: 40px;
  transform: rotate(180deg);
  width: 10px;
  height: 10px;
}
.arrow path {
  transition: all 0.2s ease-in-out;
  fill: var(--text-datk);
}

.titleContainer {
  text-align: left;
}

@media screen and (max-width: 1068px) {
  .container {
    display: grid;
    grid-template-columns: 60px 1fr 10px;
    max-height: none;
    grid-template-areas:
      'i t a'
      'i d a';
  }
  .titleContainer p {
    display: none;
    grid-area: t;
  }
  .imageContainer {
    grid-area: i;
  }
  .date {
    grid-area: d;
    margin-left: 0;
    text-align: left;
  }
  .arrow {
    grid-area: a;
    margin-left: auto;
    transform: rotate(180deg);
  }
}
