.main {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: flex-start;
  border: 1px solid var(--lines-grey2);
  border-radius: 4px;
}

.container {
  display: grid;
  margin-left: 220px;
  min-height: 100vh;
  padding: 20px;
  grid-template-columns: 1.2fr 0.8fr;
  column-gap: 20px;
  row-gap: 20px;
}

.tiresContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.pageHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.button {
  grid-area: button;
  min-height: 36px;
  max-height: 36px;
  font-family: "Roboto";
  font-size: 14px;
  padding: 4px 15px;
  line-height: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  position: relative;
}

.button:hover path {
  fill: var(--cta-red-hover);
}

.button:active path {
  fill: var(--cta-red-pressed);
}

.button:disabled path {
  fill: var(--soft-grey4);
}

.buttonIcon {
  margin-right: 8px;
}

.progressbarContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionsBlock {
  margin: 32px 0 20px 0;
  width: 100%;
}

.actionButton {
  min-height: 36px;
}

@media screen and (max-width: 1250px) {
  .container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 912px) {
  .container {
    margin: 0 12px;
    grid-template-columns: 1fr;
    padding: 20px 0;
    margin-top: 45px;
  }
}

@media screen and (max-width: 600px) {
  .pageHeader {
    margin-bottom: 8px;
    flex-direction: column;
    align-items: flex-start;
  }

  .filterButtonBlock {
    width: 100%;
  }

  .title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 520px) {
  .container {
      margin-left: 0;
      margin-right: 0;
  }
}