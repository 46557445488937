.container {
  min-height: 100vh;
  max-height: 100vh;
  max-width: 220px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: fixed;
  border-right: 1px solid var(--lines-grey2);
}

.logoContainer {
  width: 100%;
  max-height: 30px;
}

.logo {
  width: 100%;
  height: 100%;
}

.logoContainer::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -20px;
  background-color: var(--lines-grey2);
}

.navContainer {
  display: flex;
  flex-direction: column;
  margin-top: 41px;
  position: relative;
}

.navLink {
  transition: all 0.2s ease-in-out;
  width: 100%;
  color: var(--text-datk);
  font-size: 12px;
  line-height: 16px;
  font-family: 'Roboto';
  min-height: 36px;
  max-height: 36px;
  position: relative;
  padding-left: 32px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.navLink:hover {
  color: var(--cta-red-hover);
}

.navLink::after {
  content: '';
  display: block;
  position: absolute;
  width: 2px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
  background-color: var(--cta-red-default);
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.navIcon {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.navIcon path {
  transition: fill 0.2s ease-in-out;
  fill: var(--text-datk);
}

.navLink:hover .navIcon path {
  fill: var(--cta-red-hover);
}

.active,
.active path {
  color: var(--cta-red-default);
  fill: var(--cta-red-default);
}

.active::after {
  opacity: 1;
}

.navFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.navFooterText {
  color: var(--icons-grey5);
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 20px;
}

.userContainer {
  display: flex;
  width: 100%;
  height: 28px;
  margin-top: 40px;
  align-items: center;
}

.userAvatar {
  max-width: 28px;
  max-height: 28px;
  margin-right: 10px;
}

.userName {
  color: var(--text-datk);
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 16px;
}

.userDots {
  margin-left: auto !important;
}

.userDots path {
  fill: var(--icons-grey5);
}

.userDots:hover path {
  fill: var(--cta-red-hover);
}

.menuItem {
  padding: 8px 12px;
  font-family: 'Roboto' !important;
  font-size: 12px !important;
}

.menuItemIcon {
  margin-right: 9px;
}

.mobileButtons {
  display: none;
  text-align: left;
  border: none;
  background: none;
}

.eventButton {
  position: relative;
  &:disabled:hover {
    &::before {
      content: 'Вы уже зарегистрированы на все мероприятия';
      position: absolute;
      height: max-content;
      padding: 6px;
      bottom: 0;
      right: 0;
      background-color: var(--text-datk);
      color: #fff;
      border-radius: 4px;
      transform: translateY(-50%) translateX(0);
      z-index: 9999;
    }
  }
}

.withBadge {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cartItemsBadge {
  height: 20px;
  width: 20px;
  background: rgba(210, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #D20000;
}

@media screen and (max-width: 912px) {
  .container {
    z-index: 100;
    top: 0;
    right: 0;
    transform: translateX(400px);
    transition: transform 0.1s ease-in-out;
    min-width: 220px;
  }
  .logoContainer {
    display: none;
  }
  .navContainer {
    margin-top: 42px;
  }
  .container.expanded {
    transform: translateX(0);
  }
  .navFooter {
    display: none;
  }
  .mobileButtons {
    display: initial;
  }
}
