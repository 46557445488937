.footer {
  text-align: center;
  padding: 20px;
  border-top: 1px solid var(--lines-grey2);
  button {
    background-color: #fff;
    border: 1px solid var(--soft-grey4);
    color: var(--text-datk);
    max-height: 36px;
    min-height: 36px;
    max-width: 380px;
    &:hover,
    &:active,
    &:focus {
      background-color: #fff;
    }
  }
}

.logo {
  margin-bottom: 20px;
  height: 100px;
  width: 100px;
}

.description {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-datk);
}

.header {
  padding: 20px 20px 24px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-datk);
}

.closeIcon {
  cursor: pointer;
}

.textContainer {
  padding: 12px;
  margin: 0px 20px 25px 20px;
  border: 1px solid var(--lines-grey2);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: start;
}

.imageContainer {
  height: 104px;
  width: 104px;
  margin-right: 12px;
  text-align: center;
  img {
    max-height: 104px;
    max-width: 104px;
  }
}

.body {
}

.tireDescription {
  text-align: center;
}

.tireName {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-datk);
  margin-bottom: 12px;
}

.tireDetails {
  display: flex;
  align-items: start;
  width: 100%;
  border-bottom: 1px solid #e9ebf2;
  margin-bottom: 12px;
}

.leftBlock {
  flex: 1;
  margin-right: 12px;
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--icons-grey5);
}

.value {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: var(--text-datk);
}

.rightBlock {
  flex: 1;
}

.price {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-datk);
}

.emptySpace {
  flex: 0.5;
}

.mobileContainer {
  padding: 12px;
  margin: 0px 20px 25px 20px;
  border: 1px solid var(--lines-grey2);
  box-sizing: border-box;
  border-radius: 4px;
}

.mobileHead {
  display: flex;
  align-items: flex-start;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--lines-grey2);
  margin-bottom: 12px;
}

.mobileTitleBlock {
}

.mobileBody {
}

@media screen and (max-width: 700px) {
  .imageContainer {
    height: 68px;
    width: 68px;
    img {
      max-height: 68px;
      max-width: 68px;
    }
  }

  .tireName {
    font-size: 12px;
    line-height: 16px;
  }

  .price {
    font-size: 12px;
    line-height: 16px;
  }

  .tireDetails {
    border-bottom: none;
    margin-bottom: 0;
    flex-direction: column;
    width: 100%;
  }

  .emptySpace {
    display: none;
  }

  .rightBlock,
  .leftBlock {
    width: 100%;
  }

  .rightBlock .detail:last-child {
    margin-bottom: 0 !important;
  }
}
