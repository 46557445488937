.textCenter {
  text-align: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  span {
    color: var(--icons-grey5);
    font-size: 12px;
    margin: 8px 0 8px 0;

    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  p {
    font-size: 14px;
    margin: 8px 0 8px 0;

    a {
      color: #2F80ED;
      text-decoration: underline;
    }
  }
}

.textTitleBlock {
  display: flex;
  align-items: center;
  span {
    font-family: 'Jost';
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    color: var(--text-datk);
  }
}

.button {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  min-height: 24px;
  max-height: 24px;
  height: 24px;
  max-width: 130px;
  margin-left: auto;
  .copy {
    margin-right: 12px;
  }
  path {
    transition: fill .1s ease-in-out;
  }
  &:hover {
    & path {
      fill: var(--cta-red-hover);
    }
  }
}

.partners {
  margin: 0 40px 40px 40px;
  p {
    font-size: 14px;
  }
  a {
    font-size: 12px;
  }
}

.footer {
  display: flex;
  gap: 12px;
  padding: 19px 40px;
  border-top: 1px solid var(--lines-grey2);
}

.logo {
  margin-bottom: 28px;
}

@media screen and (max-width: 680px) {
  .footer {
    padding: 20px;
    gap: 20px;
    flex-direction: column;
  }
  .logo {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 520px) {
  .footer {
    button[type="reset"] {
      display: none;
    }
  }
  h2 {
    font-size: 16px!important;
  }
  .partners {
    margin: 0 12px 20px 12px;
  }
}