.container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  max-height: 56px;
  display: flex;
  width: 100%;
  padding: 16px 12px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid var(--lines-grey2);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartBlock {
  position: relative;
  display: flex;
  margin-right: 15px;
  margin-top: 3px;
}

.cartIcon {
  height: 20px;
  width: 20px;
}

.cartItemsCount {
  height: 14px;
  width: 14px;
  position: absolute;
  border-radius: 50%;
  background: #D20000;
  border: 1px solid #FFFFFF;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  right: -3px;
  top: -4px;
}

.logo {
  max-width: 138px;
}

.hamburgerContainer {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  margin-right: -4px;
}
.line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

@media screen and (min-width: 913px) {
  .container {
    display: none;
  }
}
