.wrapper {
  margin-left: 220px;
  padding: 20px;
  min-height: 100vh;
}

.container {
  border: 1px solid var(--lines-grey2);
  border-radius: 4px;
  background-color: #fff;
  position: relative;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 20px;
  padding-bottom: 16px;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
}

.title {
  margin-top: 16px;
}

.main {
  display: flex;
  flex-wrap: nowrap;
}

.avatarBlock {
  margin-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.avatarMobileButtons {
  display: none;
}

.avatarMobileButton {
  max-height: 24px;
  min-height: 24px;
}

.avatarBlock span {
  font-size: 12px;
  color: var(--icons-grey5);
  margin-bottom: 20px;
  line-height: 16px;
}

.avatar {
  width: 260px;
  height: 260px;
  margin-bottom: 24px;
}

.formSubtitle {
  margin-bottom: 16px;
  margin-top: 20px;
}

.formBlock {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border-left: 1px solid var(--lines-grey2);
  margin-bottom: 21px;
  display: flex;
  flex-direction: column;
}

.footer {
  padding: 19px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--lines-grey2);
}

.footer button {
  max-width: 180px;
  margin-right: 20px;
}

.footer button:last-child {
  max-width: 180px;
  margin-right: 0;
}

@media screen and (max-width: 989px) {
  .avatar {
    width: 200px;
    height: 200px;
  }
  .avatarBlock {
    margin-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    max-width: 240px;
  }
}

@media screen and (max-width: 912px) {
  .wrapper {
    margin-left: 0;
    padding-left: 12px;
    padding-right: 12px;
  }
  .container {
    margin-top: 45px;
  }
  .header {
    position: relative;
    z-index: 99;
  }
  .avatarMobileButtons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--lines-grey2);
  }

  .avatarMobileButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 49%;
    cursor: pointer;
    border: 1px solid var(--text-datk);
    border-radius: 4px;
    color: var(--text-datk);
    &:hover {
      border: 1px solid var(--cta-red-hover)!important;
      color: var(--cta-red-hover);
    }
    &:hover path {
      fill: var(--cta-red-hover);
    }
    input {
      display: none;
    }
    svg {
      width: 12px;
      margin-right: 8px;
    }
    path {
      fill: var(--text-datk);
    }
  }
}

@media screen and (max-width: 676px) {
  .main {
    flex-direction: column;
    align-items: center;
  }
  .avatarBlock {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }
  .avatarMobileButton {
    width: 48%;
  }
}

@media screen and (max-width: 520px) {
  .wrapper {
    padding-right: 0;
    padding-left: 0;
  }
  .footer {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    button {
      &:first-child {
        margin-bottom: 16px;
      }
      max-width: 100%!important;
      min-width: inherit;
    }
  }
}

