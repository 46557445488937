.container {
  display: flex;
  flex-direction: row;
  padding: 12px;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid var(--lines-grey2);
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 16px;
}

.imageContainer {
  height: 104px;
  min-width: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 12px auto 0;

  .image {
    max-width: 104px;
    max-height: 104px;
  }
}

.descriptionBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-datk);
}

.detailsBlock {
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  border-right: 1px solid var(--lines-grey2);
  .leftBlock,
  .rightBlock {
    flex: 1;
    margin-right: 12px;
  }
}

.detail {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  .label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--icons-grey5);
    margin-right: 12px;
  }

  .value {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: var(--text-datk);
  }
}

.actionBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}

.price {
  margin-bottom: 12px;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: var(--text-datk);
}

.button {
  min-width: 109px;
  min-height: 24px;
  padding: 4px 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  position: relative;
}

.buttonIcon {
  margin-right: 6px;
}

.buttonCheckIcon {
  margin-right: 6px;
  height: 12px;
  width: 8px;
}

.mediaBlock {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .imageContainer {
    height: 68px;
    min-width: 68px;

    .image {
      max-width: 68px;
      max-height: 68px;
    }
  }

  .title {
    font-size: 12px;
  }

  .descriptionBlock {
    justify-content: space-between;
  }

  .actionBlock {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .actions {
    flex: 1;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .price {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 16px;
  }

  .mediaBlock {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--lines-grey2);
  }

  .detailsBlock {
    margin-top: 4px;
    flex-direction: column;
    margin: 0;
    border: none;
    .leftBlock,
    .rightBlock {
      margin-right: 0;
    }
  }

  .button {
    max-width: 140px;
  }
}
