.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-self: start;
  min-height: calc(100vh - 40px);
  border: 1px solid var(--lines-grey2);
}

.positions {
  display: flex;
  flex-direction: column;
}

.position {
  padding-bottom: 12px;
  border-bottom: 1px dashed var(--lines-grey2);
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.positionIndex {
  background-color: var(--input-grey1);
  padding: 10px;
  color: var(--cta-red-default);
  border: 1px solid var(--input-grey1);
  border-radius: 4px;
  font-family: 'Jost';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  min-width: 40px;
  text-align: center;
  min-height: 40px;
}

.positionContentBlock {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.positionTitle {
  color: var(--icons-grey5);
  font-size: 12px;
}

.positionContent {
  color: var(--text-datk);
  font-size: 14px;
}

.positionPrice {
  margin-left: auto;
}

.inPlace {
  margin-left: auto;
  color: var(--icons-grey5);
  font-size: 12px;
  text-align: right;
}

.userName {
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 12px;
}

.userMail {
  font-size: 14px;
  color: var(--icons-grey5);
  margin-bottom: 20px;
}

.summaryBlock {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  font-family: 'Jost';
  font-size: 14px;
  font-weight: 500;
  background-color: var(--input-grey1);
  border-radius: 4px;
  margin-bottom: 24px;
}

.summary {
  font-size: 16px;
  background-color: var(--input-grey1);
  border: none;
  outline: none;
  cursor: default;
}

.radioTitle {
  margin-bottom: 12px;
}

.radio {
  margin-bottom: 12px;
}
.radio svg {
  max-height: 72px;
  margin-left: 13px;
}

.radio span {
  text-align: left;
  margin-left: 12px !important;
}

.policy {
  margin-top: auto;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-datk);
}

.policy span {
  max-width: calc(100% - 40px);
}

.subpolicy {
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 20px;
}

@media screen and (max-width: 912px) {
  .container {
    h2 {
      font-size: 16px;
    }
  }
  .positionTitle {
    margin-bottom: 4px;
  }
  .radioTitle {
    margin-bottom: 12px;
  }
  .policy {
    span {
      margin-left: 0 !important;
    }
  }
}
