.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  background-color: #fff;
  border: 1px solid var(--lines-grey2);
  border-radius: 4px;
  color: var(--text-datk);
}

.checkbox span:last-child {
  margin-left: auto;
  color: var(--icons-grey5);
}

.checkbox::before {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 4px;
  background-color: var(--input-grey1);
  border: 1px solid var(--soft-grey4);
  margin-right: 8px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .2s ease-in-out;
}

.input {
  position: absolute;
  z-index: -9999;
  opacity: 0;
}

.checked {
  border-color: var(--cta-red-default);
  background-color: rgba(255, 221, 221, 0.2);
}
.checked::before {
  background-color: var(--cta-red-default);
  background-image: url(../../assets/icons/check.svg);
  border-color: var(--cta-red-default);
}