.app {
  background-color: var(--bg-grey0);
  width: 100%;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.preloadCircular {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
}

.allert {
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: 320px;
  font-weight: 500;
  &None {
    display: none;
  }
}

.alertInner {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 912px) {
  .allert {
    top: 60px;
  }
}

@media screen and (max-width: 520px) {
  .container {
    margin: 0 12px;
  }
}
