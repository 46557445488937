.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-self: start;
  min-height: calc(100vh - 40px);
  border: 1px solid var(--lines-grey2);
  border-radius: 4px;
}

.pageHeader {
}

.title {
  line-height: 32px;
}

.userInfoBlock {
  margin: 20px 0;
}

.name {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-datk);
  margin-bottom: 12px;
}

.email {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--icons-grey5);
}

.cartBlock {
}

.totalPriceContainer {
  padding-top: 12px;
  border-top: 1px dashed var(--lines-grey2);
  border-bottom: 1px solid var(--lines-grey2);
}

.priceBlock {
  padding: 12px;
  background: var(--input-grey1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.label {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-datk);
}

.price {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-datk);
}

.emptyCart {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--icons-grey5);
  margin-bottom: 12px;
}

.backButtonContainer {
  display: inline-block;
  margin-bottom: 16px;
}

.backButtonLink {
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #d20000;
  > svg {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }
}

.actionsBlock {
  width: 100%;
  margin-top: 36px;
}

.backButton {
  min-height: 36px;
  background-color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-datk);
  border: 1px solid #bbbfc9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;
  justify-content: center;
}

.payButton {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  justify-content: center;
}

.radioBlock {
  margin-top: 24px;
}

.subTitle {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-datk);
  margin-bottom: 12px;
}

.radioGroup {
}

.radio {
  margin-bottom: 12px;
  min-height: 116px;
  padding-bottom: 80px;
  span:last-child {
    margin-left: 6px;
  }
}

.radio::before {
  top: 26px;
}

.radioText {
  margin-left: 8px;
}

.selectComponent {
  height: 56px;
  .select__control {
    height: 56px;
  }
}

.selectComponentWrapper {
  position: absolute;
  top: 48px;
  width: 85%;
  left: 50px;
}

.selectOption {
  display: flex;
  flex-direction: column;
}

.selectOptionTitle {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-datk);
  margin-bottom: 4px;
}

.selectOptionDescription {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-datk);
}

.selectEventsOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectEventsOptionContainer {
}

.selectEventsOptionDate {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-datk);
  margin-right: 0;
}

.paymentsBlock {
  margin-top: 24px;
}

.paymentRadio {
  margin-bottom: 12px;
  padding: 15px 12px;
  padding-left: 40px;
}
.paymentRadio svg {
  max-height: 44px;
  margin-left: 12px;
}

.paymentRadio span {
  text-align: left;
  margin-left: 12px !important;
}

.policy {
  margin-top: auto;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-datk);
}

.policy span {
  max-width: calc(100% - 40px);
}

.subpolicy {
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.actions {
  margin-top: 40px;
}

@media screen and (max-width: 1350px) {
  .selectComponentWrapper {
    width: 82%;
  }
}

@media screen and (max-width: 1250px) {
  .actions {
    margin-top: 32px;
  }
  .policy {
    span {
      margin-left: 12px !important;
    }
  }
}

@media screen and (max-width: 912px) {
  .container {
    margin-top: 70px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 16px;
  }
  .policy {
    span {
      margin-left: 0 !important;
    }
  }
}

@media screen and (max-width: 520px) {
  .container {
    padding: 16px;
    margin-left: 0;
    margin-right: 0;
  }

  .title {
    line-height: 20px;
  }

  .radio {
    padding-bottom: 98px;
    min-height: 134px;
  }

  .selectComponent {
    height: 76px;
    .select__control {
      height: 76px;
    }
  }

  .selectEventsOption {
    flex-direction: column;
    align-items: self-end;
    justify-content: space-between;
  }

  .selectOptionTitle {
    margin-bottom: 4px;
  }

  .selectEventsOptionDate {
    margin-top: 8px;
  }

  .policy {
    align-items: flex-start;
    &::before {
      margin-top: 4px;
    }
  }
}

@media screen and (max-width: 470px) {
  .selectComponentWrapper {
    width: 80%;
  }
}

@media screen and (max-width: 360px) {
  .selectComponentWrapper {
    width: 75%;
  }
}
